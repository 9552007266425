.successContainer {
  display: flex;
  align-items: center;
  color: var(--color-success-dark);
  background-color: var(--color-success-light);
  padding: 16px;
}

.successIcon {
  color: var(--color-success);
}

.handleNames {
  font-weight: bolder;
  color: var(--color-success-dark);
}

.loadingContainer {
  text-align: center;
}

.animationGif {
  height: 200px;
  width: 200px;
}

.emailConfirmContainer {
  margin: 20px 0;
}
.emailConfirmEmailUpdate {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  margin: 8px 0;
  box-sizing: border-box;
  color: var(--color-text-primary-dark);
}

.emailConfirmTitle {
  margin-bottom: 5px;
}

.emailUpdateText {
  color: var(--color-primary);
  font-weight: 600;
  cursor: pointer;
}

.formGroup {
  margin-top: 12px;
  padding: 5px 0 5px 0;
}

.formLabel {
  color: var(--color-text-primary-dark);
  font-weight: 500;
}

.formControl {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  display: block;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
}

.formControl:focus {
  border-color: var(--color-primary);
}

.signUpButton {
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-text-on-primary);
  background-color: var(--color-primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0;
  padding: 12px 20px;
}

.connectButton {
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-text-on-primary);
  background-color: var(--color-primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0;
  padding: 12px 20px;
}

.connectButton:disabled {
  cursor: default;
  background-color: var(--color-primary);
  opacity: 0.6;
}

.connectionError {
  display: flex;
  color: var(--color-text-error);
  background-color: var(--color-error-light);
  padding: 16px;
}

.connectionErrorIcon {
  color: var(--color-error);
}

.connectionColumn {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.connectionErrorTitle {
  color: var(--color-text-error-dark);
  margin-bottom: 10px;
}

.fbIcon {
  margin-right: 10px;
}

.fbLink {
  text-decoration: none;
}

.learnMoreLinkContainer {
  margin-top: 10px;
  text-align: center;
}

.warningIcon {
  color: var(--color-warning);
  width: 120px;
  height: 60px;
  margin-right: 16px;
}

.storiesWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
}
