:root {
  --color-background: white;
  --color-primary: #4f46e5;
  --color-text-primary: #6b7280;
  --color-secondary: #7c7ca4;
  --color-secondary-300: #E9E9F0;
  --color-secondary-700: #252467;
  --color-text-primary-dark: #374151;
  --color-text-title: #111827;
  --color-text-link: var(--color-primary);
  --color-text-on-primary: white;
  --color-border: #d1d5db;
  --color-error: #ef4444;
  --color-error-light: #fef2f2;
  --color-text-error: #b91c1c;
  --color-text-error-dark: #991b1b;
  --color-success-light: #ecfdf5;
  --color-success: #059669;
  --color-success-dark: #065f46;
  --color-warning: #ffb717;
}
