.formGroup {
  margin-top: 12px;
  padding: 5px 0 5px 0;
}

.formLabel {
  color: var(--color-text-primary-dark);
  font-weight: 500;
}

.formControl {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  display: block;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-sizing: border-box;
}

.formControlError {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  display: block;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-sizing: border-box;
  border-color: var(--color-text-error);
}

.subtitleError {
  font-size: 10px;
  color: var(--color-text-error);
}

.postsForm {
  padding-top: 20px;
}

.feedFormTitle {
  display: flex;
  justify-content: center;
}

.submitButton {
  cursor: pointer;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-text-on-primary);
  background-color: var(--color-primary);
}

.submitButton:disabled {
  cursor: default;
  background-color: var(--color-secondary-300);
  color: var(--color-secondary-700);
  opacity: 0.6;
}

.apiErrorContainer {
  display: flex;
  color: var(--color-text-error);
  background-color: var(--color-error-light);
  padding: 16px;
}

.apiErrorIcon {
  color: var(--color-error);
}

.apiErrorColumn {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.apiErrorTitle {
  color: var(--color-text-error-dark);
}
