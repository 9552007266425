.modal {
  padding: 20px;
}

.modalHeader {
  padding: 5px;
  text-align: center;
}

.modalBody {
  padding: 5px;
}

.modalFooter {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalFooter > :not(:last-child) {
  margin-bottom: 20px;
}

.poweredByLoudcrowd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.poweredByLoudcrowd > :not(:last-child) {
  margin-right: 12px;
}

.poweredByLoudcrowdLoudcrowd {
  color: var(--color-primary);
  font-weight: 600;
}

.loudcrowdLink {
  text-decoration: none;
}

.hostLogo {
  border-radius: 50%;
  width: 60px;
}

.hostError {
  color: white;
  background-color: var(--color-error);
}

.hostError {
  display: flex;
  text-align: left;
  color: var(--color-text-error);
  background-color: var(--color-error-light);
  padding: 16px;
}

.hostErrorIcon {
  color: var(--color-error);
}

.hostErrorColumn {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.connectionErrorTitle {
  color: var(--color-text-error-dark);
}

.instagramButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  color: var(--color-text-on-primary);
  background-color: var(--color-primary);
}

.instagramIcon {
  margin-right: 10px;
}

.closeButton {
  cursor: pointer;
  border: 1px solid var(--color-border);
  background-color: var(--color-background);
  border-radius: 6px;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
}

.loadingContainer {
  text-align: center;
}

.animationGif {
  height: 300px;
  width: 300px;
}

.closeButtonContainer {
  text-align: right;
}

.cancelIcon {
  cursor: pointer;
  height: 16px;
  width: 16px;
  color: var(--color-text-primary-dark);
}
