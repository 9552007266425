@import-normalize;
@import './theme/font.css';
@import './theme/colors.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--base-font-size);
  color: var(--color-text-primary);
  background-color: var(--color-background);
}

h4 {
  color: var(--color-text-primary-dark);
  font-weight: 600;
}
