.skeletonRoot {
  text-align: center;
}

.skeleton {
  background-color: var(--color-border);
}

.skeleton:after {
  display: block;
  content: "";
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
  right top, from(transparent),
  color-stop(rgba(255, 255, 255, 0.2)),
  to(transparent));

  background: linear-gradient(90deg, transparent,
  rgba(255, 255, 255, 0.2), transparent);

  /* Adding animation */
  animation: loading 0.8s infinite;
}

.avatar {
  margin: 0 auto;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.avatar:after {
  width: 60px;
  height: 60px;
}

.programHost {
  margin: 10px auto;
  height: 32px;
  width: 25%;
}

.programHost::after {
  width: 75%;
  height: 32px;
}

.formCopy {
  margin: 10px auto;
  height: 16px;
  width: 75%;
}

.formCopy::after {
  height: 16px;
  width: 75%;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
