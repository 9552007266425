.formGroupCollections {
  padding: 5px 0 5px 0;
}

.formGroupItem {
  column-gap: 5px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 5px 0 5px 0;
}

.formGroupCollectionsItemLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: currentColor;
}

.formGroupCollectionsItemLink svg {
  margin-right: 10px;
}

.formGroup {
  margin-top: 12px;
  padding: 5px 0 5px 0;
}

.formGroupDate {
  composes: formGroup;
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.formLabel {
  color: var(--color-text-primary-dark);
  font-weight: 500;
}

.formControl {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  display: block;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-sizing: border-box;
}

.formControlPhoneInput {
  composes: formControl;
  display: flex;
}

/**
* remove the default number input arrow styling
*/
.formControl::-webkit-inner-spin-button,
.formControl::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formControl[type='number'] {
  -moz-appearance: textfield;
}

.formControlError {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  display: block;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-sizing: border-box;
  border-color: var(--color-text-error);
}

.formControlErrorInput {
  composes: formControlError;
  display: flex;
}

.formCheckBoxControl {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkBox {
  margin-right: 25px;
}

.subtitleError {
  font-size: 10px;
  color: var(--color-text-error);
}
