.container {
  margin-bottom: 10px;
  border: 1px solid var(--color-border);
}

.containerHighlight {
  margin-bottom: 10px;
  border: 2px solid orange;
}

.header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.headerTitle {
  margin: 0;
}

.body {
  padding: 0 20px 20px 20px;
}

.icon {
  cursor: pointer;
  height: 16px;
  width: 16px;
  color: var(--color-text-primary-dark);
}